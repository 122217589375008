@tailwind base;

body {
  @apply text-sm font-normal text-master antialiased; /* 14px */
  font-family: "Segoe UI", Arial, sans-serif;
  letter-spacing: 0.01em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-master-dark antialiased;
  margin: 10px 0;
  font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-weight: 300;
}
h1 {
  font-size: 44px;
  line-height: 55px;
  letter-spacing: -0.08px;
}
h2 {
  font-size: 31px;
  line-height: 40px;
}
h3 {
  font-size: 27px;
  line-height: 35px;
}
h4 {
  font-size: 22px;
  line-height: 31.88px;
}
h5 {
  font-size: 18px;
  line-height: 25.88px;
}

/* Below tailwind "lg" breakpoint */
@media (max-width: 1024px) {
  body,
  p {
    @apply text-smaller; /* 13px */
    line-height: 1.5;
  }

  h1 {
    font-size: 33px;
    line-height: 44px;
    letter-spacing: -0.08px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 24px;
    line-height: 35.88px;
  }
  h4 {
    font-size: 18px;
    line-height: 33.88px;
  }
  h5 {
    font-size: 16px;
    line-height: 25.88px;
  }
  small,
  .small {
    font-size: 89%;
    line-height: 17px;
  }
}

hr {
  @apply my-5 border-t border-gray-300;
}

/* .checkbox {
  @apply my-3;
  & label {
    @apply my-4 pl-2 relative text-xxs font-bold;
    top: -2px;
  }
} */

.text-pink-600-important {
  @apply text-pink-600 !important;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.underline-dotted {
  @apply underline;
  text-decoration-style: dotted;
}

body.prevent-body-scroll {
  overflow: hidden;
  /* position: fixed; // messes up table width */
}

/* Custom scrollbar (for Chrome) */
::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.06);
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-button {
  display: none;
}

.hide-scrollbar::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.hide-number-arrows::-webkit-outer-spin-button,
.hide-number-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 
.title {
  @apply text-5xl text-center;
  color: #333;
  line-height: 1.15;
} */

amplify-authenticator {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}
:root {
  --amplify-primary-color: #1890ff;
  --amplify-primary-tint: #69c0ff;
  --amplify-primary-shade: #e6f7ff;
}

@tailwind components;

.layout-grid {
  @apply grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: 60px calc(100vh - 60px);
}

.search-arrow {
  position: relative;
  &::after {
    position: absolute;
    pointer-events: none;
    right: 0.4rem;
    top: calc(50% - (3px));
    content: "╲╱";
    font-size: 6px;
    line-height: 1;
  }
}

.even-shading:nth-child(even) {
  @apply bg-lightShading;
}

.show-only-two li:not(:nth-child(-n + 2)) {
  display: none;
}

.packageIcon::after {
  content: " 📦";
  font-size: 0.5rem;
}

.warningIcon::after {
  content: "❗";
  font-size: inherit;
}

.transition-side {
  transition:
    right 300ms,
    left 300ms;
}

.tableFormat td {
  border: 1px solid #ccc;
  padding: 6px 8px;
}

/* Rotate animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.rotating {
  animation: rotate 1.2s infinite linear;
}

/* Fade animation */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 0.9s linear;
}

.fade-in-and-rotate {
  animation:
    fade-in 1s linear,
    rotate 1.2s infinite linear;
}

/* Row Backgrounds */
.row-bg-yellow {
  @apply bg-yellow-200 !important;
  &:hover {
    background-color: #faf089 !important;
  }
}
.row-bg-red {
  @apply bg-red-200 !important;
  &:hover {
    background-color: #fcb2b2 !important;
  }
}

/* Buttons */
.btn {
  @apply m-1 py-2 px-4 font-semibold leading-snug border rounded transition-colors duration-150;
  &:focus {
    @apply outline-none;
  }
  &:active {
    position: relative;
    top: 1px;
    left: 1px;
  }
  &:disabled {
    @apply opacity-50 pointer-events-none;
  }
}

.btn-gray {
  @apply text-master bg-transparent border-sidebar-light;
  &:hover {
    @apply bg-light border-master;
  }
}

.btn-gray-light {
  @apply text-master bg-transparent;
  &:hover {
    @apply bg-light;
  }
}

.btn-blue {
  @apply text-blue-700 bg-transparent border-blue-500;
  &:hover {
    @apply text-white bg-blue-500 border-transparent;
  }
}

.btn-blue-reverse {
  @apply text-white bg-blue-500;
  &:hover {
    @apply text-blue-700 bg-transparent border-blue-500;
  }
}

.btn-red {
  @apply text-white bg-red-600 border-red-800;
  &:hover {
    @apply text-red-600 bg-transparent;
  }
}

/* Toggle */

.toggle-container {
  @apply relative inline-block w-8 h-4;
}
.toggle-slider {
  @apply absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-gray-500;
  transition: 0.4s;
  border-radius: 0.625rem;
  &:before {
    @apply absolute h-3 w-3 bg-white;
    content: "";
    left: 0.125rem;
    bottom: 0.125rem;
    transition: 0.4s;
    border-radius: 50%;
  }
}
.toggle-input {
  @apply opacity-0 w-0 h-0;
  &:checked + .toggle-slider {
    background-color: #2196f3;
  }
  &:focus + .toggle-slider {
    box-shadow: 0 0 1px #2196f3;
  }
  &:checked + .toggle-slider:before {
    transform: translateX(0.9375rem);
  }
}

@media print {
  @page {
    size: 17in 11in;
  }
  .layout-grid {
    display: block;
    & > nav,
    & > aside {
      display: none;
    }
  }
  #table-header-top {
    & > h4 {
      @apply py-2 font-bold bg-transparent;
    }
    & div,
    & button {
      display: none;
    }
  }
  #table-header-filters {
    @apply bg-transparent;
    & th > div {
      display: none;
    }
  }
  .hidePrint {
    display: none !important;
  }
}

@tailwind utilities;
